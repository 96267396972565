<template>
    <div id="admin"></div>
</template>

<script>
export default {
    name: 'Admin',
    created() {
        this.$router.push('admin/bills')
    }
}
</script>

<style lang="scss"></style>
